<template>
  <TsCard
    :append-class="
      twJoin('max-h-[inherit] flex flex-col px-4', isMobile && 'rounded-b-none')
    "
    body-class="overflow-auto grow"
    :header-class="twJoin(isDesktop && 'pb-4')"
  >
    <template #header>
      <TsMedia append-class="bg-natural-soft-white sticky top-0 z-10">
        <TsIcon
          name="bitcoin-icons:cross-filled"
          @click="emit('close')"
          class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
        />

        <TsMediaStart append-class="mt-4 mx-2 pl-2 bg-white rounded-xl pr-2">
          <NuxtImg
            v-if="product.image"
            class="rounded-lg"
            :src="product.image"
            alt="product"
            width="80"
            height="80"
            loading="lazy"
          />
        </TsMediaStart>
        <TsMediaContent append-class="pt-6">
          <TsRow append-class="m-0 pr-7">
            <TsTypography v-if="product.full_name" append-class="mb-0 text-idle-black">
              <span class="font-bold">{{
                product.full_name.split(" ")[0]
              }}</span>
              {{ product.full_name?.split(" ").slice(1).join(" ") }}
            </TsTypography>
          </TsRow>
        </TsMediaContent>
      </TsMedia>
    </template>

    <template #content>
      <template v-if="props.loading">
        <TsBranchTransition v-for="n in 2" :key="n" />
      </template>

      <div v-else>
        <TsMedia>
          <TsMediaStart>
            <TsRow append-class="m-0">
              <TsColumn>
                <TsQuantityCounter
                  v-model="selectedQuantity"
                  data-testid="quantity-selector"
                  :disabled="!!loadingCta"
                  :disable-decrement="!!loadingCta"
                  :disable-increment="!!loadingCta"
                />
                <TsTag
                  v-if="enableOutOfStock"
                  variant="danger"
                  label="Out of stock"
                  size="xs"
                  rounded
                  append-class="my-2 px-6"
                />
              </TsColumn>
            </TsRow>
          </TsMediaStart>

          <TsMediaContent append-class="pl-6 mt-2">
            <div class="flex">
              <TsTypography
                size="sm"
                v-if="(product?.prices && product?.prices?.raw && product?.prices?.was_price_formatted)
                    && product?.prices?.raw.gross < product?.prices.was_price_raw"
                append-class="line-through leading-tight mr-1"
              >
                {{ `${product?.prices?.was_price_formatted}` }}
              </TsTypography>

              <TsTypography
                v-if="product?.prices?.formatted.discount_percentage !== '0%'"
                size="sm"
                weight="bold"
                append-class="text-success leading-tight"
              >
                ({{ product?.prices?.formatted.discount_percentage }}
                OFF)
              </TsTypography>
            </div>

            <TsTypography
              variant="h2"
              weight="bold"
              append-class="text-idle-black leading-normal mb-0 leading-tight"
            >
              {{
                rootStore.isIncVat
                  ? product.prices?.formatted.gross
                  : product.prices?.formatted.net
              }}
              <TsTypography
                v-if="product.prices?.formatted.per_unit"
                as="span"
                variant="xs"
                append-class="text-natural-silver-grey leading-tight"
              >
                {{ product.prices?.formatted.per_unit }}
              </TsTypography>
            </TsTypography>

            <TsTypography as="span" variant="xs" append-class="leading-tight">
              {{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
              {{
                rootStore.isIncVat
                  ? product.prices?.formatted.net
                  : product.prices?.formatted.gross
              }}
            </TsTypography>
          </TsMediaContent>
        </TsMedia>

        <TsTypography
          v-if="branchStore.is_branch_set && !enableOutOfStock"
          size="sm"
          append-class="text-idle-black my-2"
          >Available for click and collect at
          <span v-if="branchStore.lastSavedBranch" class="font-bold">
            {{ branchStore.lastSavedBranch.name }}
          </span>
          &nbsp;
          <span
            @click="emit('setBranch')"
            class="text-primary underline cursor-pointer"
            >Change</span
          ></TsTypography
        >
        <TsColumn v-if="enableOutOfStock" append-class="p-0 py-2">
          <TsButton
            v-if="isSubscribedToStock"
            icon="material-symbols:unsubscribe-outline"
            :label="unsubscribeLabel"
            @click="handleStockUnsubscribe(props.product.code)"
            append-class="mt-2"
            :data-testid="props.dataTestid + '-stock-unsubscribe-cta'"
            size="sm"
            :is-loading="isStockUnsubscribeCTAloading"
            outlined
            block
          />
          <TsButton v-else size="sm" append-class="mt-2" block @click="emit('notify')"
          :data-testid="props.dataTestid + '-stock-notification-cta'"
            ><TsIcon name="mdi:bell-outline" size="28" />{{ notifyMeLabel }}</TsButton
          >
        </TsColumn>
        <TsColumn v-else append-class="p-0 py-2">
          <template v-if="!branchStore.is_branch_set">
            <TsButton size="sm" block @click="emit('setBranch')">
              <TsIcon name="mynaui:store" size="28" /> &nbsp; Set branch for
              click and collect</TsButton
            >
          </template>

          <template
            v-else-if="
              !props.product?.direct_ship && props.product?.click_and_collect
            "
          >
            <TsButton
              v-if="enableNextDayCollection"
              :is-loading="props.loadingCta === 'NextDayCollection'"
              :disabled="
                props.loadingCta
                  ? props.loadingCta !== 'NextDayCollection'
                  : false
              "
              size="sm"
              block
              @click="onAddToTrolleyForNextDayCollection"
            >
              <TsIconButtonLoader
                v-if="props.loadingCta === 'NextDayCollection'"
                class="size-6"
              />
              <TsIcon v-else name="mynaui:store" size="28" /> &nbsp; Next Day
              Collection (
              {{ props.product.stockDetails?.delivery }}
              in stock )</TsButton
            >

            <TsButton
              v-else
              size="sm"
              block
              :is-loading="props.loadingCta === 'Collection'"
              :disabled="
                props.loadingCta ? props.loadingCta !== 'Collection' : false
              "
              @click="onAddToTrolleyForCollection"
            >
              <TsIconButtonLoader
                v-if="props.loadingCta === 'Collection'"
                class="size-6"
              />
              <TsIcon v-else name="mynaui:store" size="28" /> &nbsp; Collection
              (
              {{ props.product.stockDetails?.collection }}
              in stock )</TsButton
            >
          </template>

          <TsButton
            size="sm"
            :disabled="disableDeliveryCTA"
            :is-loading="
              props.loadingCta === 'Delivery' ||
              props.loadingCta === 'Directship'
            "
            append-class="mt-2"
            block
            @click="onAddToTrolleyForDelivery"
          >
            <TsIconButtonLoader
              v-if="
                props.loadingCta === 'Delivery' ||
                props.loadingCta === 'Directship'
              "
              class="size-6"
            />
            <TsIcon v-else name="mdi:truck-delivery-outline" size="28" /> &nbsp;
            Delivery ( {{ props.product.stockDetails?.delivery }} in stock
            )</TsButton
          >
        </TsColumn>
      </div>

    </template>
  </TsCard>
</template>

<script setup lang="ts">
import { twJoin } from "tailwind-merge";
import type { Product } from "~/types/ecom/product/product.type";
import { data as dummyProductVariant } from "~/json/product.json";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";

const rootStore = useRootStore();
const branchStore = useBranchStore();

const { isMobile, isDesktop } = useDevice();

const selectedQuantity = defineModel<number>("quantity", {
  default: 1,
});

type Props = {
  product: Product;
  dataTestid?: string;
  loadingCta?: string;
  loading?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  product: () => dummyProductVariant,
});

const emit = defineEmits<{
  close: any;
  notify: any;
  setBranch: any;
  addToTrolley: [
    productCode: string,
    quantity: number,
    channel: TrolleyChannel
  ];
}>();

const notifyMeLabel = useTranslation("notifyMe", "Breng mij op de hoogte");
const unsubscribeLabel = useTranslation("unsubscribeFromStock", "Afmelden voor voorraadmelding");

/* OOS notification */
const isStockUnsubscribeCTAloading = ref(false);

async function handleStockUnsubscribe(productCode: string) {
  isStockUnsubscribeCTAloading.value = true;
  await useAuthStore().onStockUnsubscribe(productCode);
  isStockUnsubscribeCTAloading.value = false;
}

const isSubscribedToStock = computed(() => {
  const authStore = useAuthStore();
  if (!authStore.user || !(authStore.user as any).id) return false;

  const accountStore = useAccountStore();

  return accountStore.stock_notifications.some((notification: any) => {
    return notification.product_id === props.product.code;
  });
});

/* Trolley decisions */
const enableNextDayCollection = computed<boolean>(() => {
  return (
    (props.product.next_business_day_collection &&
      props.product.outOfStockForCollection &&
      !props.product.outOfStockForDelivery) ||
    (selectedQuantity.value > props.product.stockDetails?.collection! &&
      selectedQuantity.value <= props.product.stockDetails?.delivery!)
  );
});

const enableOutOfStock = computed<boolean>(() => {
  return (
    (props.product.outOfStockForCollection &&
      props.product.outOfStockForDelivery) ||
    (selectedQuantity.value > props.product.stockDetails?.collection! &&
      selectedQuantity.value > props.product.stockDetails?.delivery!)
  );
});

const disableDeliveryCTA = computed(
  () =>
    !props.product.stockDetails?.delivery 
    || (props.loadingCta === 'Collection' || props.loadingCta === 'NextDayCollection') 
    || (selectedQuantity.value > props.product.stockDetails.delivery)
);

function onAddToTrolleyForCollection() {
  emit(
    "addToTrolley",
    props.product.code,
    selectedQuantity.value,
    TrolleyChannel.Collection
  );
}
function onAddToTrolleyForNextDayCollection() {
  emit(
    "addToTrolley",
    props.product.code,
    selectedQuantity.value,
    TrolleyChannel.NextDayCollection
  );
}
function onAddToTrolleyForDelivery() {
  emit(
    "addToTrolley",
    props.product.code,
    selectedQuantity.value,
    props.product.direct_ship
      ? TrolleyChannel.Directship
      : TrolleyChannel.Delivery
  );
}

const random_id = ref("");
onMounted(() => {
  random_id.value = useRandomUUID();
});
</script>
